import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Header() {
  let year = new Date().getFullYear();
  return (
    //footer
    <footer className="userFooter">
      <div className="container">
        <p className="mb-0 text-center">
          © {year} UGain India. All Rights Reserved.
        </p>
      </div>
    </footer>
   
  );
}
