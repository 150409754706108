import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import clsx from "classnames";
// import lib
import isEmpty from "../../lib/isEmpty";
import config from "../../config";
import { toastAlert } from "../../lib/toastAlert";

// import action
import { getBalanceData, setBalanceData } from "../../actions/users";

// import context
import SocketContext from "../Context/SocketContext";
export default function ExchangeBalance(props) {
  const { params } = props;
  const socketContext = useContext(SocketContext);
  let history = useNavigate();

  // console.log(params, "paramsparamsparamsparamsparamsparams");
  const dispatch = useDispatch();
  const initialFormValue = {
    exchange: params.exchange,
    showBalance: false,
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const [loader, setLoader] = useState(false);
  const [eyeLoader, setEyeLoader] = useState(false);
  const { exchange, showBalance } = formValue; // state
  const [balanceData, setBalance] = useState({});

  // function
  useEffect(() => {
    fetchData();

    socketContext.socket.on("ExchangeBalance", (result) => {
      setBalance(result);
    });
  }, []);

  const eyeClick = async (e) => {
    try {
      e.preventDefault();
      setEyeLoader(true);
      let formData = { ...formValue, ...{ showBalance: !showBalance } };
      setFormValue(formData);
      if (
        !showBalance
        // ((exchange == "Binance" &&
        //   isEmpty(balanceData && balanceData.binanceStatus)) ||
        //   (exchange == "Kucoin" &&
        //     isEmpty(balanceData && balanceData.kucoinStatus)) ||
        //   (exchange == "BitMart" &&
        //     isEmpty(balanceData && balanceData.bitmartStatus)))
      ) {
        let reqData = {
          exchange: exchange,
        };
        const { status, result } = await setBalanceData(reqData);
        if (status == "success") {
          setBalance(result);
          setEyeLoader(false);
          setLoader(true);
        }
      } else {
        setEyeLoader(false);
      }
    } catch (err) {
      console.log(err, "----------50");
    }
  };
  const fetchData = async () => {
    let reqData = {
      exchange: exchange,
    };
    const { status, result } = await getBalanceData(reqData);
    if (status == "success") {
      setBalance(result);
      setLoader(true);
    }
  };
  // const handleChange = (e) => {
  //   e.preventDefault();
  //   const { name, value } = e.target;
  //   const url = "/trade/" + params.pair + "/" + value + "/" + params.bot;

  //   let formData = { ...formValue, ...{ [name]: value } };
  //   setFormValue(formData);

  //   window.location.href = url;
  // };

  const gotoTrade = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    history("/trade/" + params.pair + "/" + value + "/" + params.bot);
    window.location.reload();
  };

  return (
    <>
      <select
        onChange={gotoTrade}
        name="exchange"
        value={exchange}
        className="form-select"
      >
        <option value="Binance">Binance</option>
        <option value="Kucoin">Kucoin</option>
        <option value="BitMart">BitMart</option>
      </select>{" "}
      {/* {((balanceData && balanceData.binanceStatus && exchange == "Binance") ||
        (balanceData && balanceData.kucoinStatus && exchange == "Kucoin") ||
        (balanceData &&
          balanceData.bitmartStatus &&
          exchange == "BitMart")) && ( 
        <>*/}
      {!eyeLoader && (
        <Link onClick={(e) => eyeClick(e)}>
          <i
            className={clsx(
              "bi",
              { "bi-eye": showBalance },
              { "bi-eye-slash": !showBalance }
            )}
            aria-hidden="true"
          ></i>
        </Link>
      )}
      {/* </>
      )} */}
      {balanceData &&
        balanceData.binanceStatus &&
        exchange == "Binance" &&
        balanceData.binance &&
        balanceData.binance.length > 0 &&
        balanceData.binance.map((item, i) => {
          return (
            <div className="d-flex align-items-center justify-content-between mt-1">
              <span className="f_16">{item.asset} Balance:</span>
              {showBalance == false ? (
                <span className="f_16">**********</span>
              ) : (
                <span className="f_16">{item.free}</span>
              )}
            </div>
          );
        })}
      {exchange == "Binance" &&
        loader &&
        balanceData &&
        !balanceData.binStatus && (
          <span className="ms-2 f_16">Please Bind Your API Key</span>
        )}
      {balanceData &&
        balanceData.kucoinStatus &&
        exchange == "Kucoin" &&
        balanceData.kucoin &&
        balanceData.kucoin.length > 0 &&
        balanceData.kucoin.map((item, i) => {
          return (
            <div className="d-flex align-items-center justify-content-between mt-1">
              <span className="f_16">{item.currency} Balance:</span>
              {showBalance == false ? (
                <span className="f_16">**********</span>
              ) : (
                <span className="f_16">{item.available}</span>
              )}
            </div>
          );
        })}
      {exchange == "Kucoin" &&
        loader &&
        balanceData &&
        !balanceData.kucStatus && (
          <span className="ms-2 f_16">Please Bind Your API Key</span>
        )}
      {balanceData &&
        balanceData.bitmartStatus &&
        exchange == "BitMart" &&
        balanceData.bitmart &&
        balanceData.bitmart.length > 0 &&
        balanceData.bitmart.map((item, i) => {
          return (
            <div className="d-flex align-items-center justify-content-between mt-1">
              <span className="f_16">{item.currency} Balance:</span>
              {showBalance == false ? (
                <span className="f_16">**********</span>
              ) : (
                <span className="f_16">{item.available}</span>
              )}
            </div>
          );
        })}
      {exchange == "BitMart" &&
        loader &&
        balanceData &&
        !balanceData.bitStatus && (
          <span className="ms-2 f_16">Please Bind Your API Key</span>
        )}
      {/*<div className="d-flex align-items-center justify-content-between mt-1">
        <span className="f_16">USDT Balance:</span>
        <span className="f_16">52.2085</span>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-1">
        <span className="f_16">BTC Balance:</span>
        <span className="f_16">8.0256</span>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-1">
        <span className="f_16">ETH Balance:</span>
        <span className="f_16">10.1014</span>
      </div>*/}
    </>
  );
}
